var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        staticStyle: { "background-color": "#f5f5f5" },
        attrs: {
          title: "用户登录/注册",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c(
        "div",
        { staticClass: "login-container" },
        [
          _c("van-image", {
            staticStyle: { "margin-bottom": "30px" },
            attrs: { round: "", width: "80px", height: "80px", src: _vm.logo },
          }),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              label: "手机号",
              placeholder: "请输入手机号",
            },
            model: {
              value: _vm.form.phone,
              callback: function ($$v) {
                _vm.$set(_vm.form, "phone", $$v)
              },
              expression: "form.phone",
            },
          }),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              label: "短信验证码",
              placeholder: "请输入短信验证码",
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c(
                      "van-button",
                      {
                        attrs: {
                          size: "small",
                          type: "default",
                          disabled: _vm.sendCodeDisabled,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.sendCode.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.sendCodetText))]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.form.code,
              callback: function ($$v) {
                _vm.$set(_vm.form, "code", $$v)
              },
              expression: "form.code",
            },
          }),
          _c(
            "van-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                type: "primary",
                size: "large",
                round: "",
                color: "#93A3D6",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确定")]
          ),
          _c(
            "div",
            { staticClass: "user-agreement-box" },
            [
              _c(
                "van-checkbox",
                {
                  attrs: { "icon-size": "14px" },
                  on: { change: _vm.changeCheck },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                [_vm._v("我已阅读并同意")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "user-agreement-link",
                  staticStyle: { "line-height": "20px" },
                  attrs: { to: "/userAgreement" },
                },
                [_vm._v("《用户注册协议》")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "user-agreement-link",
                  staticStyle: { "line-height": "20px" },
                  attrs: { to: "/userNotice" },
                },
                [_vm._v("《养眼调理须知》")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }