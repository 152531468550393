import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { boDebounce } from '@/utils/common'
import { getVerificationCode, login } from '@/api/login';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _Form.name, _Form), _defineProperty(_components, _Toast.name, _Toast), _defineProperty(_components, _Checkbox.name, _Checkbox), _components),
  data: function data() {
    return {
      logo: require('@/assets/logo.jpg'),
      form: {
        store: localStorage.getItem('adl_store_id'),
        phone: '',
        code: ''
      },
      checked: false,
      sendCodeDisabled: false,
      sendCodetText: '发送验证码',
      totalCount: null,
      timer: null // 倒计时

    };
  },
  created: function created() {},
  methods: {
    // 发送验证码
    sendFunc: function sendFunc() {
      var _this = this;

      if (!this.form.store) {
        _Toast('请通过门店二维码扫码进入');

        return false;
      } else if (!this.form.phone) {
        _Toast('请输入手机号码');

        return false;
      } else if (this.form.phone && !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.phone)) {
        _Toast('请输入正确的手机号码');

        return false;
      } else if (!this.checked) {
        _Toast('请阅读并勾选底部协议');

        return false;
      }

      var TIME_COUNT = 60;

      if (!this.timer) {
        this.totalCount = TIME_COUNT;
        this.sendCodeDisabled = true;
        this.sendCodetText = '倒计时 ' + this.totalCount + ' s';
        this.timer = setInterval(function () {
          if (_this.totalCount > 0 && _this.totalCount <= TIME_COUNT) {
            _this.totalCount--;
            _this.sendCodetText = '倒计时 ' + _this.totalCount + ' s';
          } else {
            _this.sendCodetText = '重新发送';
            _this.sendCodeDisabled = false;
            clearInterval(_this.timer);
            _this.timer = null;
          }
        }, 1000);
      }

      getVerificationCode({
        store: this.form.store,
        phone: Number(this.form.phone)
      }).then(function (res) {
        if (res.error.code === 0) {
          _Toast('验证码发送成功');
        } else {
          _this.sendCodeDisabled = false;
        }
      }).catch(function () {
        _this.sendCodeDisabled = false;
      });
    },
    // 发送验证码
    // sendCode: boDebounce('sendFunc', 3000, true),
    sendCode: function sendCode() {
      this.sendFunc();
    },
    // 确认登录
    onSubmit: function onSubmit() {
      var _this2 = this;

      if (!this.form.phone) {
        _Toast('请输入手机号码');

        return false;
      } else {
        if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.phone)) {
          _Toast('请输入正确的手机号码');

          return false;
        } else if (!this.form.code) {
          _Toast('请输入手机验证码');

          return false;
        } else if (this.form.code.length !== 6) {
          _Toast('请输入6位验证码');

          return false;
        } else if (!this.checked) {
          _Toast('请阅读并勾选底部协议');

          return false;
        }
      }

      var data = {
        phone: this.form.phone,
        store: this.form.store,
        code: this.form.code
      };
      login(data).then(function (res) {
        if (res.error.code === 0) {
          localStorage.setItem('TOKEN', res.data);

          _Toast('登录成功');

          _this2.$router.push({
            name: 'personal',
            query: {
              fromLogin: 1
            }
          });
        }
      });
    },
    // 勾选用户协议
    changeCheck: function changeCheck(e) {
      this.checked = e;
    },
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};